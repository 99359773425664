.chatContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
}

.responses {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
}

.chatInputContainer {
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  background-color: white;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.chatInput {
  flex-grow: 1;
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  outline: none;
}

.chatInput:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.sendIcon {
  cursor: pointer;
  color: #3b82f6;
}

.sendIcon:hover {
  color: #2563eb;
}
