.container {
  background-image: url("../../assets/landing-page-background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: space-between;
  border-bottom: 1px solid #6c603b;
  align-items: center;
  margin-bottom: 32px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 200px;
}

.signUpButton {
  background-color: #382f1c;
  opacity: 0.8;
  color: #e6dfd3;
  border: none;
  padding: 8px 20px;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Chivo Mono", monospace;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.33);
}

.signUpButton:hover {
  opacity: 0.9;
  cursor: pointer;
}

.subtitle {
  font-family: "Chivo Mono", monospace;
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

.golden {
  color: #947d47;
}

.italics {
  font-style: italic;
}

.leftContent {
  color: #3a3017;
}

.title {
  font-family: "Libre Caslon Text", serif;
  font-size: 68px;
  font-weight: 500;
  margin: 0;
  margin-top: 16px;
}

.bold {
  font-weight: 600;
}

.description {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 24px;
  color: black;
  margin-top: 16px;
}

.inputContainer {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.33);
  border-radius: 12px;
}

.websiteInput {
  background-color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 12px 0px 0px 12px;
  font-size: 20px;
  width: 400px;
  font-family: "IBM Plex Sans", sans-serif;
  height: 60px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #5b5140;
  border-right: none;
}

.websiteInputButton {
  background-color: #382f1c;
  opacity: 0.8;
  border: none;
  color: #e6dfd3;
  font-size: 20px;
  padding: 8px 20px; /* Remove vertical padding to match input */
  border-radius: 0px 12px 12px 0px;
  font-family: "Chivo Mono", monospace;
  height: 60px;
  box-sizing: border-box;
}

.websiteInputButton:hover {
  opacity: 0.9;
  cursor: pointer;
}

.websiteInputButton::placeholder {
  color: #a2a2a2;
}

.ps {
  font-family: "Chivo Mono", monospace;
  font-size: 16px;
  margin-top: 16px;
  color: #3a3017;
  font-weight: 600;
}

.rightContent {
  border: 5px solid black;
  border-radius: 12px;
  background-color: #e7ddd6;
  padding: 32px;
  color: black;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.billTitle {
  margin: 0;
}

.demoText {
  font-size: 16px;
  text-align: center;
}

.rightContentTitle {
  font-family: "Libre Caslon Text", serif;
  text-align: center;
}

.bill {
  background-color: #f2e7dc;
  padding: 16px 32px;
  box-shadow: 2px 7px 7px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 400px;
}

.loadingBill {
  background-color: #f2e7dc;
  box-shadow: 2px 7px 7px rgba(0, 0, 0, 0.15);
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
}

.upperBillBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.upperBillBarText {
  font-size: 16px;
}

.bottomContent {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  align-items: center;
}

.bottomContent {
  text-decoration: none;
  font-family: "Chivo Mono", monospace;
  font-size: 16px;
  font-weight: 600;
  color: #5b5140;
}

.link {
  text-decoration: underline;
  font-family: "Chivo Mono", monospace;
  font-size: 16px;
  font-weight: 600;
  color: #5b5140;
}

.link:hover {
  color: #382f1c;
}

.billContainer {
  border-radius: 12px;
  overflow: hidden;
  max-width: 600px;
}

.resultArrow {
  font-size: 64px;
  color: #382f1c;
  margin: 0 24px;
}

.manifesto {
  font-family: "Chivo Mono", monospace;
  margin: 100px 0px;
}

.manifestoTitle {
  font-size: 46px;
  font-weight: 500;
  margin: 0;
  color: #947d47;
}

.manifestoText {
  font-size: 24px;
  color: black;
  margin-top: 16px;
}

.manifestoText i {
  font-weight: 600;
}

.manifestoText i {
  color: #947d47;
}

.boldManifesto {
  color: #947d47;
}

.navButtons {
  display: flex;
  gap: 16px;
  align-items: center;
}

.navButton {
  background: none;
  border: none;
  color: #382f1c;
  font-family: "Chivo Mono", monospace;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 16px;
}

.navButton:hover {
  color: #947d47;
}

@media screen and (max-width: 1300px) {
  .title {
    font-size: 58px;
  }
  .subtitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 1100px) {
  .websiteInput {
    width: 350px;
  }
  .container {
    padding: 12px 24px;
  }
}

@media screen and (max-width: 1000px) {
  .content {
    flex-direction: column;
    margin: auto 16px;
    gap: 32px;
  }

  .leftContent {
    text-align: center;
  }

  .websiteInput {
    width: 400px;
  }
}

@media screen and (max-width: 675px) {
  .websiteInput {
    width: 300px;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 48px;
  }
  .description {
    font-size: 20px;
  }
  .subtitle {
    font-size: 24px;
  }
  .websiteInput {
    width: 250px;
    font-size: 16px;
  }
  .logo {
    width: 150px;
  }
  .signUpButton {
    font-size: 16px;
  }
  .bill {
    width: 225px;
  }
  .rightContentTitle {
    font-size: 24px;
  }
  .navButton {
    font-size: 16px;
    padding: 8px 12px;
  }
}

@media screen and (max-width: 400px) {
  .bill {
    width: 200px;
  }
  .websiteInput {
    width: 200px;
  }
  .signUpButton {
    font-size: 14px;
  }
  .logo {
    width: 125px;
  }
  .title {
    font-size: 42px;
  }
}
