.container {
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  box-sizing: border-box;
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translateZ(0);
} 
