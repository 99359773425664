.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f4f0ea;
}
.whiteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff;
  padding: 1.5rem;
}

.card {
  background: white;
  border-radius: 32px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 480px;
  border: 1px solid #e2d9cc;
}
.whiteCard {
  background: white;
  border-radius: 32px;
  padding: 2rem;
  width: 100%;
  max-width: 480px;
}

.title {
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 2rem;
  margin-left: 0.5rem;
  font-size: 2.5rem;
  font-weight: 600;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-family: "Libre Caslon Text", serif;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formGroup input {
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem; /* Added padding for the left */
  padding-right: 0.5rem; /* Added padding for the right */

  border: 1px solid #e2d9cc;
  border-radius: 6px;
  font-size: 0.95rem;
  color: #4a4a4a;
  transition: all 0.2s ease;
  background-color: white;
  box-sizing: border-box; /* Ensures padding is included in the element's total width/height */
}

.formGroup textarea {
  width: 100%;
  height: 100px; /* Adjust this value as needed for the desired height */
  padding: 0.75rem;
  border: 1px solid #e2d9cc;
  border-radius: 6px;
  font-size: 0.95rem;
  color: #4a4a4a;
  transition: all 0.2s ease;
  background-color: white;
  box-sizing: border-box; /* Ensures padding is included in the element's total width/height */
}

.formGroup textarea:focus {
  outline: none;
  border-color: #998e7b;
  box-shadow: 0 0 0 3px rgba(153, 142, 123, 0.1);
}

.formGroup textarea::placeholder {
  color: #998e7b;
}

.formGroup input:focus {
  outline: none;
  border-color: #998e7b;
  box-shadow: 0 0 0 3px rgba(153, 142, 123, 0.1);
}

.formGroupRow {
  display: flex;
  gap: 1rem;
}

.formGroup {
  flex: 1;
}

.formGroup input::placeholder {
  color: #998e7b;
}

.forgotPassword {
  text-align: right;
}

.forgotPassword a {
  color: #998e7b;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.2s ease;
  font-family: "Libre Caslon Text", serif;
}
.signup {
  font-family: "Libre Caslon Text", serif; /* Set the font */
  color: #4a4a4a; /* Set the color */
  font-size: 0.95rem; /* Set the font size */
}

.forgotPasswordSignUp {
  font-family: "Libre Caslon Text", serif; /* Set the font */
  color: #4a4a4a; /* Set the color */
  font-size: 0.95rem; /* Set the font size */
  margin-top: 1rem; /* Add some space above the links */
}

.forgotPasswordSignUp a {
  color: #998e7b;
  text-decoration: none;
  transition: color 0.2s ease;
  font-family: "Libre Caslon Text", serif;
}

.signup a {
  color: #998e7b;
  text-decoration: none;
  transition: color 0.2s ease;
  font-family: "Libre Caslon Text", serif;
}
.linksContainer {
  display: flex;
  justify-content: space-between; /* Positions links on opposite sides */
  align-items: center; /* Aligns items vertically centered */
  margin-top: 1rem; /* Adds some space above the links */
  width: 100%; /* Ensure it takes the full width */
}

.forgotPassword a:hover {
  color: #4a4a4a;
}

.button {
  background-color: #e9decc;
  color: black;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease; /* Added transition for shadow */
  letter-spacing: 0.5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Adjust shadow parameters as needed */
}
.button:hover {
  background-color: #887d6a;
}

.button.loading {
  opacity: 0.7;
  cursor: not-allowed;
}

.error {
  background-color: #f8e6e6;
  color: #c62828;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  text-align: center;
  border: 1px solid rgba(198, 40, 40, 0.2);
}

.success {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  text-align: center;
  border: 1px solid rgba(46, 125, 50, 0.2);
}

@media (max-width: 480px) {
  .container {
    padding: 1rem;
  }

  .card {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  .form {
    gap: 1rem;
  }

  .formGroup input {
    padding-right: 0;

    font-size: 0.875rem;
  }

  .button {
    padding: 0.625rem 0.875rem;
    font-size: 0.875rem;
  }
}

.formGroup select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}

.formGroup select.placeholder {
  color: #757575;
}

.formGroup select:focus {
  outline: none;
  border-color: #007bff;
}

/* Style for number input arrows */
.formGroup input[type="number"]::-webkit-inner-spin-button,
.formGroup input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  height: 24px;
}

/* Add these new styles to your existing Auth.module.css */

.inputWithButtons {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.inputWithButtons input {
  width: 100%;
  padding: 0.75rem;
  padding-right: 16rem; /* Make space for buttons */
  border: 1px solid #e2d9cc;
  border-radius: 6px;
  font-size: 0.95rem;
  color: #4a4a4a;
  transition: all 0.2s ease;
  background-color: white;
}

.inputWithButtons input:focus {
  outline: none;
  border-color: #998e7b;
  box-shadow: 0 0 0 3px rgba(153, 142, 123, 0.1);
}

.inputButtons {
  position: absolute;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.inputButton {
  padding: 0.5rem 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  color: #998e7b;
  transition: all 0.2s ease;
}

.inputButton:hover {
  background-color: rgba(233, 222, 204, 0.1);
}

.primaryInputButton {
  background-color: #e9decc;
  color: black;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.primaryInputButton:hover {
  background-color: #887d6a;
  color: white;
}

.inputButton.loading {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Add responsive styles */
@media (max-width: 480px) {
  .inputWithButtons input {
    padding-right: 12rem; /* Slightly less space for buttons on mobile */
  }

  .inputButton {
    padding: 0.4rem 0.8rem;
    font-size: 0.875rem;
  }
}
