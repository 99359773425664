@import "@react-pdf-viewer/search/lib/styles/index.css";

.pageContainer {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: #f8f9fa;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.pdfSection {
  height: 100%;
  border-right: 1px solid #e9ecef;
  background-color: white;
  overflow: hidden;
}

.pdfWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pdfWrapper > div {
  flex: 1;
  overflow: hidden;
}

.chatSection {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.uploadContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: white;
}

.uploadContent {
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.uploadLabel {
  display: block;
  margin-bottom: 16px;
  font-size: 1.125rem;
  color: #343a40;
  font-weight: 500;
}

.fileInput {
  display: block;
  width: 100%;
  padding: 12px;
  margin: 0 auto;
  border: 2px dashed #ced4da;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.fileInput:hover {
  border-color: #adb5bd;
  background-color: #f8f9fa;
}

.chatPlaceholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  padding: 20px;
  text-align: center;
  font-size: 1.1rem;
}

.dragBar {
  background-color: #f8f9fa;
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.dragBar:hover {
  background-color: #e9ecef;
}

.dragIcon {
  color: #6c757d;
  transition: color 0.2s ease;
}

.dragBar:hover .dragIcon {
  color: #495057;
}

/* Fix for PDF viewer toolbar */
.pdfWrapper :global(.rpv-core__viewer) {
  height: calc(100% - 40px) !important;
}

/* Ensure proper scaling of PDF pages */
.pdfWrapper :global(.rpv-core__viewer-zone) {
  height: 100% !important;
}

/* Smooth transitions for resizing */
.pdfSection,
.chatSection {
  transition: width 0.1s ease;
}