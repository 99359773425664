.container {
  display: grid;
  grid-template-columns: 0.7fr 2fr 4fr 1fr;
  gap: 1rem;
  padding: 1rem;
  align-items: center;
  transition: background-color 0.2s ease;
  background-color: #ffffff;
}

.id {
  font-weight: 600;
}

.container:hover {
}

.container div {
  font-size: 0.95rem;
  color: #4a4a4a;
  line-height: 1.5;
}

.title {
  font-weight: 500;
}

.summary {
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
}

.lastActionDate {
  white-space: nowrap;
  text-align: center;
}

.lastAction {
  font-family: monospace;
  font-size: 0.875rem;
}
