.footer {
  color: #6C603B;
  font-family: "Chivo Mono", monospace;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  margin-top: 0px;
  width: 100%;
}

.divider {
  border: none;
  border-top: 1px solid #6c603b;
  margin: 24px 0;
  width: 100%;
} 