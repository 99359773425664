.container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  justify-content: space-between;
  border-bottom: 1px solid #6c603b;
  align-items: center;
  margin-bottom: 32px;
}

.logo {
  width: 200px;
}

.navButtons {
  display: flex;
  gap: 16px;
  align-items: center;
}

.navButton {
  background: none;
  border: none;
  color: #382f1c;
  font-family: "Chivo Mono", monospace;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 16px;
}

.navButton:hover {
  color: #947d47;
}

.signUpButton {
  background-color: #382f1c;
  opacity: 0.8;
  color: #e6dfd3;
  border: none;
  padding: 8px 20px;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Chivo Mono", monospace;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.33);
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.title {
  text-align: center;
  font-family: "Libre Caslon Text", serif;
  color: #382f1c;
  font-size: 48px;
  margin-bottom: 24px;
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding: 0 20px;
}

.teamMember {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #382f1c;
  transition: transform 0.2s;
}

.teamMember:hover {
  transform: translateY(-5px);
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 16px;
  border: 3px solid #947d47;
}

.memberName {
  font-family: "Chivo Mono", monospace;
  font-size: 24px;
  margin: 0;
  margin-bottom: 8px;
  text-align: center;
}

.memberRole {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 18px;
  margin: 0;
  color: #947d47;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .teamGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .teamGrid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  
  .container {
    padding: 12px 20px;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 36px;
  }
  
  .avatar {
    width: 150px;
    height: 150px;
  }
  
  .memberName {
    font-size: 20px;
  }
  
  .memberRole {
    font-size: 16px;
  }
}