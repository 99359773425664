.container {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.searchContainer {
  margin-bottom: 1.5rem;
}

.search {
  width: 100%;
  max-width: 400px;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid #e2d9cc;
  border-radius: 6px;
  background: white
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23998e7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'/%3E%3C/svg%3E")
    no-repeat 0.75rem center;
  font-size: 0.95rem;
  color: #4a4a4a;
  transition: all 0.2s ease;
}

.search:focus {
  outline: none;
  border-color: #998e7b;
  box-shadow: 0 0 0 3px rgba(153, 142, 123, 0.1);
}

.search::placeholder {
  color: #998e7b;
}

.tableContainer {
  border-radius: 6px;
  overflow: hidden;
}

.upperRow {
  display: grid;
  grid-template-columns: 0.7fr 0.7fr 2fr 4fr 1fr 0.2fr;
  gap: 1rem;
  padding: 0.75rem 1rem;
  background-color: #f9f0e4;
  border-bottom: 1px solid #f9f0e4;
}

.upperRow div {
  font-weight: 600;
  font-size: 0.875rem;
  color: #443c28;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
}

.upperRow div p {
  margin-right: 6px;
}

@media (max-width: 1024px) {
  .upperRow {
    grid-template-columns: 0.7fr 0.7fr 1.5fr 3fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .tableContainer {
    overflow-x: auto;
  }

  .upperRow {
    min-width: 900px;
  }
}
